import { ParsedUrlQuery } from 'querystring'

import { getPageFromQueryString } from '@nx/helpers'

import { AlgoliaClientInstance } from '../algoliaClient'
import {
  AlgoliaLotsResponse,
  getAlgoliaLots as getAlgoliaLotsLib,
} from './getAlgoliaLots'
import { AlgoliaLotIndices } from './types'

export function makeAlgoliaLotQueries(client: AlgoliaClientInstance) {
  const getAlgoliaLots = getAlgoliaLotsLib(client)

  return async ({
    auctionId,
    numberOfLots,
    query,
    queryString,
    sortBy,
    minPrice,
    maxPrice,
    departments,
    groups,
    lotItemIds,
    lotsPerPage,
  }: {
    auctionId: string
    numberOfLots: number
    query: ParsedUrlQuery
    queryString: string
    sortBy: AlgoliaLotIndices
    minPrice: number
    maxPrice: number
    departments: string[]
    groups: string[]
    lotItemIds: number[]
    lotsPerPage: number
  }): Promise<
    [
      AlgoliaLotsResponse,
      AlgoliaLotsResponse | undefined,
      AlgoliaLotsResponse,
      AlgoliaLotsResponse | undefined,
    ]
  > => {
    // https://www.algolia.com/doc/api-reference/api-parameters/hitsPerPage/ - maximum value is 1000
    const maximumHitsPerPageForAlgolia = 1000

    const requestMaximumAllowedLotsForAuction = getAlgoliaLots({
      auctionId,
      hitsPerPage: maximumHitsPerPageForAlgolia,
    })

    // If we can't get back all the lots for the auction in the above query, we need
    // to run a second query to get the remainder (this assumes there will never be
    // more than 2000 lots in an auction)
    const requestAdditionalLotsForAuction =
      numberOfLots > maximumHitsPerPageForAlgolia
        ? getAlgoliaLots({
            auctionId,
            hitsPerPage: maximumHitsPerPageForAlgolia,
            page: 1,
          })
        : undefined

    // The page query string param determines how many lots we want to fetch
    // in the initial page impression
    const hitsPerPage = lotsPerPage * getPageFromQueryString(query)

    const requestMaximumAllowedLotsForAuctionSearch = getAlgoliaLots({
      auctionId,
      hitsPerPage,
      query: queryString,
      index: sortBy,
      minPrice,
      maxPrice,
      departments,
      groups,
      lotItemIds,
    })

    const requestAdditionalLotsForAuctionSearch =
      numberOfLots > maximumHitsPerPageForAlgolia &&
      hitsPerPage > maximumHitsPerPageForAlgolia
        ? getAlgoliaLots({
            query: queryString,
            index: sortBy,
            auctionId,
            minPrice,
            maxPrice,
            departments,
            groups,
            lotItemIds,
            hitsPerPage,
            page: 1,
          })
        : undefined

    client.executeAlgoliaQueries()

    return Promise.all([
      requestMaximumAllowedLotsForAuction,
      requestAdditionalLotsForAuction,
      requestMaximumAllowedLotsForAuctionSearch,
      requestAdditionalLotsForAuctionSearch,
    ])
  }
}
