import { SearchOptions, SearchResponse } from '@algolia/client-search'

import { getDateNowUtc } from '@nx/dates'
import {
  AuctionType,
  EAuctionBiddingStatus,
  EAuctionStatus,
  EAuctionType,
  EBrand,
} from '@nx/global-types'
import { daysToSeconds } from '@nx/helpers'

import { AlgoliaClientInstance } from '../algoliaClient'
import {
  AlgoliaFilter,
  buildFilterString,
  extractFacets,
  generateFacets,
  generateFacetsQueries,
} from '../algoliaHelpers'
import { AlgoliaAuction, AlgoliaAuctionIndices } from './types'

export type AlgoliaAuctionResponse = SearchResponse<AlgoliaAuction>

export type GetAlgoliaAuctionsParams = SearchOptions & {
  index?: AlgoliaAuctionIndices
  locationCodes?: string[]
  category?: string | string[]
  department?: string | string[]
  categoryCode?: string | string[]
  departmentCode?: string | string[]
  regionCode?: string | string[]
  country?: string | string[]
  monthAndYear?: string | string[]
  daysFromNow?: number
  futureOnly?: boolean
  auctionStatus?:
    | EAuctionStatus
    | AlgoliaFilter<EAuctionStatus>
    | (AlgoliaFilter<EAuctionStatus> | EAuctionStatus)[]
  auctionType?: EAuctionType | EAuctionType[]
  biddingStatus?:
    | EAuctionBiddingStatus
    | AlgoliaFilter<EAuctionBiddingStatus>
    | AlgoliaFilter<EAuctionBiddingStatus>[]
  brand:
    | EBrand
    | EBrand[]
    | AlgoliaFilter<EBrand>
    | AlgoliaFilter<EBrand>[]
    | null
  attributesToInclude?: string[]
}

export function getAlgoliaAuctions({
  algoliaRequest,
  executeAlgoliaQueries,
}: AlgoliaClientInstance) {
  return async ({
    index = 'auctions_sort_hammer_time_asc',
    locationCodes = [],
    category = [],
    department = [],
    categoryCode = [],
    departmentCode = [],
    regionCode = [],
    country = [],
    monthAndYear = [],
    daysFromNow,
    auctionStatus = [],
    auctionType = ['ONLINE', 'PUBLIC'],
    biddingStatus = [],
    attributesToInclude = [],
    brand,
    futureOnly = false,
    ...searchArgs
  }: GetAlgoliaAuctionsParams): Promise<AlgoliaAuctionResponse> => {
    if (Array.isArray(auctionType) && !auctionType.length) {
      auctionType = ['ONLINE', 'PUBLIC']
    }

    const filters = [
      buildFilterString('auctionStatus', auctionStatus),
      buildFilterString('departments.code', departmentCode),
      buildFilterString('region.code', regionCode),
      buildFilterString('categories.code', categoryCode),
      buildFilterString('biddingStatus', biddingStatus),
      buildFilterString('brand', brand),
      buildFilterString('venue.code', locationCodes),
    ]

    const tempNumericFilters: string[] = []

    const facetFiltersRelation: Record<string, string[]> = {
      'categories.name': [category].flat().filter(Boolean),
      'country.name': [country].flat().filter(Boolean),
      'departments.name': [department].flat().filter(Boolean),
      monthAndYear: [monthAndYear].flat().filter(Boolean),
      auctionType: [auctionType as string].flat().filter(Boolean),
    }

    const facetFilters = generateFacets(facetFiltersRelation)

    if (futureOnly) {
      let now = getDateNowUtc().seconds

      const tempType = Array.isArray(auctionType) ? auctionType : [auctionType]
      if (tempType.includes(AuctionType.online)) {
        // We're adding additional 20 hours to give some leeway for auctions with end date
        // in the past, but still going because of staggered lot ending times.
        const twentyHoursInSeconds = 20 * 3600
        now = now - twentyHoursInSeconds
      }

      const futureOnlyFilter = `dates.end.timestamp > ${now}`
      tempNumericFilters.push(futureOnlyFilter)
    }

    if (daysFromNow) {
      const now = getDateNowUtc().seconds
      tempNumericFilters.push(
        `dates.end.timestamp:${now} TO ${now + daysToSeconds(daysFromNow)}`
      )
    }

    const attributesToRetrieve = [
      '*', // retrieves all attributes
      ...['description']
        .filter((item) => !attributesToInclude.includes(item))
        .map((item) => `-${item}`), // except this list of attributes (starting with a '-')
    ]

    const mainParams: SearchOptions = {
      attributesToRetrieve,
      filters: filters.filter(Boolean).join(' AND '),
      facetFilters: facetFilters.filter(Boolean) as string[][],
      facets: ['*'],
      ...searchArgs,
      numericFilters: [
        ...tempNumericFilters,
        ...((searchArgs['numericFilters'] as string[]) ?? []),
      ],
    }

    const extraParams = generateFacetsQueries(facetFiltersRelation, mainParams)

    const mainResponse = algoliaRequest<AlgoliaAuctionResponse>(
      index,
      '',
      mainParams
    )
    const extraResponses = extraParams.map((params) =>
      algoliaRequest<AlgoliaAuctionResponse>(index, '', params)
    )
    const [mainResult, ...extraResults] = await Promise.all([
      mainResponse,
      ...extraResponses,
    ])
    executeAlgoliaQueries()

    const facets = extractFacets(extraResults)
    return {
      ...mainResult,
      facets: { ...mainResult.facets, ...facets },
    }
  }
}
