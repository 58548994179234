import { GetObjectsResponse } from '@algolia/client-search'

import { AlgoliaClientInstance } from '../algoliaClient'
import { AlgoliaAuction, AlgoliaAuctionIndices } from './types'

export type AlgoliaAuctionsByObjectIDsResponse =
  GetObjectsResponse<AlgoliaAuction>

export function getAlgoliaAuctionsByObjectIDs({
  getAlgoliaClient,
}: AlgoliaClientInstance) {
  return async ({
    index = 'auctions_sort_hammer_time_asc',
    objectIDs,
  }: GetAlgoliaAuctionsByObjectIDsParams): Promise<AlgoliaAuctionsByObjectIDsResponse> =>
    getAlgoliaClient()
      .initIndex(index)
      .getObjects<AlgoliaAuction>(
        [objectIDs]
          .flat()
          .filter(Boolean)
          .map((objectID) => objectID.toString())
      )
}

interface GetAlgoliaAuctionsByObjectIDsParams {
  index?: AlgoliaAuctionIndices
  objectIDs: string[] | number[] | number | string
}
