import { SearchOptions, SearchResponse } from '@algolia/client-search'

export interface AlgoliaFilter<T> {
  value: T
  exclude?: boolean
}

export function generateFacetsQueries(
  facetFiltersRelation: Record<string, (string | number)[]>,
  mainParams: SearchOptions
): SearchOptions[] {
  const result: SearchOptions[] = []

  Object.entries(facetFiltersRelation).forEach(([filterName, value]) => {
    if (value.length) {
      const clonedFacetFiltersRelation = { ...facetFiltersRelation }
      delete clonedFacetFiltersRelation[filterName]
      result.push({
        ...mainParams,
        page: 0,
        hitsPerPage: 0,
        facetFilters: generateFacets(clonedFacetFiltersRelation).filter(
          Boolean
        ),
        facets: [filterName],
        filters: mainParams.filters,
      })
    }
  })

  return result
}

export function generateFacets(
  facetFiltersRelation: Record<string, (string | number)[]>
) {
  return Object.entries(facetFiltersRelation).map(([filterName, value]) =>
    parseFacetFilter(filterName, value)
  )
}

export function extractFacets(extraResults: Awaited<SearchResponse>[]) {
  return extraResults.reduce((acc, result) => {
    return {
      ...acc,
      ...result.facets,
    }
  }, {})
}

export function parseFacetFilter(
  filterName: string,
  filters:
    | string
    | AlgoliaFilter<string | number>
    | (AlgoliaFilter<string | number> | string | number)[]
    | null
) {
  const tempFilters: string[] = []

  if (!filters || (Array.isArray(filters) && !filters.length)) {
    return undefined
  }

  if (Array.isArray(filters)) {
    return filters.map((filter) => {
      const exclude = typeof filter === 'object' ? filter.exclude : false
      const value = typeof filter === 'object' ? filter.value : filter

      return `${filterName}:${exclude ? '-' : ''}${value}`
    })
  }

  return tempFilters
}

export const buildFilterString = (
  filterName: string,
  filters:
    | string
    | AlgoliaFilter<string>
    | (AlgoliaFilter<string> | string)[]
    | null
) => {
  let tempFilters: string[] = []

  if (!filters || (Array.isArray(filters) && !filters.length)) {
    return ''
  }

  if (Array.isArray(filters)) {
    tempFilters = filters.map((filter) => {
      const exclude = typeof filter === 'object' ? filter.exclude : false
      const value = typeof filter === 'object' ? filter.value : filter

      return `${exclude ? 'NOT ' : ''}${filterName}:"${value}"`
    })
  } else if (isAlgoliaFilter(filters)) {
    tempFilters.push(
      `${filters.exclude ? 'NOT ' : ''}${filterName}:"${filters.value}"`
    )
  } else {
    tempFilters.push(`${filterName}:"${filters}"`)
  }

  return `(${tempFilters.join(' OR ')})`
}

export function isAlgoliaFilter(
  filter: string | AlgoliaFilter<string>
): filter is AlgoliaFilter<string> {
  return typeof filter === 'object' && 'value' in filter
}
