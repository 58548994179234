export function getDateNowUtc() {
  const date = new Date()
  const UTCtime = date.getTime() // Get milliseconds since 1/1/1970 in UTC

  return {
    milliseconds: UTCtime,
    seconds: Math.ceil(UTCtime / 1000),
    ISOstring: date.toISOString(),
    UTCstring: date.toUTCString(),
  }
}
