import { SearchOptions } from '@algolia/client-search'
import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { differenceInMinutes } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

import { getAuctionProgressStatus } from '@nx/a3'
import { AuctionResponse } from '@nx/api'
import { AuctionProgressStatus, AuctionType } from '@nx/global-types'

import { AlgoliaClientInstance } from '../algoliaClient'
import {
  AlgoliaLotsResponse,
  GetAlgoliaLotsParams,
  getAlgoliaLots,
} from './getAlgoliaLots'

export function useGetAlgoliaLots({
  name = 'auctionLots',
  auction,
  startingPage,
  filters = {},
  searchArgs = {},
  enabled = true,
  client,
  auctionLotsPerPage,
  initialData,
}: UseGetAlgoliaLotsParams) {
  let refetchOnWindowFocus = false

  if (typeof auction !== 'undefined') {
    filters['auctionId'] = String(auction.iSaleNo)

    const auctionStartDate = toZonedTime(
      auction.dates.start[0].date.datetime,
      auction.dates.start[0].date.timezone.iana
    )
    const auctionEndDate = toZonedTime(
      auction.dates.end.datetime,
      auction.dates.start[0].date.timezone.iana
    )
    const auctionProgressStatus = getAuctionProgressStatus({
      status: auction.sSaleStatus,
      type: auction.sSaleType,
      biddingStatus: auction.sBidOnlineStatus,
      endDate: auction.dates.end.datetime,
      startDate: auction.dates.start[0].date.datetime,
      localTimezone: auction.dates.start[0].date.timezone.iana,
      consignmentEndDate: auction.dates.consignment?.datetime
        ? auction.dates.consignment.datetime
        : null,
    })

    if (
      auctionProgressStatus === AuctionProgressStatus.ready ||
      auctionProgressStatus === AuctionProgressStatus.live ||
      auctionProgressStatus === AuctionProgressStatus.ending
    ) {
      if (auction.sSaleType === AuctionType.online) {
        const minutesBetweenNowAndAuctionEnd = differenceInMinutes(
          toZonedTime(
            new Date(),
            auction.dates.start[0].date.timezone.iana
          ).getTime(),
          auctionEndDate
        )

        if (minutesBetweenNowAndAuctionEnd >= -59) {
          refetchOnWindowFocus = true
        }
      } else if (auction.sSaleType === AuctionType.live) {
        const minutesBetweenNowAndAuctionStart = differenceInMinutes(
          toZonedTime(
            new Date(),
            auction.dates.start[0].date.timezone.iana
          ).getTime(),
          auctionStartDate
        )

        if (minutesBetweenNowAndAuctionStart >= 0) {
          refetchOnWindowFocus = true
        }
      }
    }
  }

  return useInfiniteQuery({
    queryKey: [name, { ...filters, ...searchArgs }],
    queryFn: ({ pageParam: page }) =>
      getAlgoliaLots(client)({
        page,
        hitsPerPage: auctionLotsPerPage,
        ...filters,
        ...searchArgs,
      }),
    initialPageParam: startingPage,
    getNextPageParam: ({ page, nbPages }) =>
      page + 1 < nbPages ? page + 1 : page,
    staleTime: 0,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus,
    enabled,
    retry: 3,
    retryDelay: 1000,
    initialData,
  })
}

interface UseGetAlgoliaLotsParams {
  name?: string
  auction?: AuctionResponse
  startingPage?: number
  filters?: GetAlgoliaLotsParams
  searchArgs?: SearchOptions
  enabled?: boolean
  client: AlgoliaClientInstance
  auctionLotsPerPage: number
  initialData?: InfiniteData<
    Omit<
      AlgoliaLotsResponse,
      'query' | 'params' | 'processingTimeMS' | 'exhaustiveNbHits'
    >,
    number | undefined
  >
}
