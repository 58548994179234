import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export const config = {
  appId: publicRuntimeConfig.ALGOLIA.appId,
  apiKey: publicRuntimeConfig.ALGOLIA.apiKey,
  host: publicRuntimeConfig.ALGOLIA.host,
} as const

export const auctionLotsPerPage = publicRuntimeConfig.AUCTION_LOTS_PER_PAGE
