import { SearchOptions, SearchResponse } from '@algolia/client-search'

import { AuctionLot, LotStatus } from '@nx/global-types'

import { AlgoliaClientInstance } from '../algoliaClient'
import {
  extractFacets,
  generateFacets,
  generateFacetsQueries,
} from '../algoliaHelpers'
import { AlgoliaLotIndices } from './types'

export type AlgoliaLotsResponse = SearchResponse<AuctionLot>

export type GetAlgoliaLotsParams = SearchOptions & {
  auctionId?: string
  index?: AlgoliaLotIndices
  minPrice?: number
  maxPrice?: number
  minGBPPrice?: number
  maxGBPPrice?: number
  departments?: string[]
  chronology?: 'past' | 'future'
  isWithoutReserve?: boolean
  excludeWithdrawn?: boolean
  country?: string[]
  brand?: string[]
  lotItemIds?: number[] | string[]
  groups?: string[]
  shouldShowSoldLots?: boolean
  filters?: string
}

export function getAlgoliaLots({
  algoliaRequest,
  executeAlgoliaQueries,
}: AlgoliaClientInstance) {
  return async ({
    auctionId,
    index = 'lots_virtual_sort_lotno_asc',
    minPrice,
    maxPrice,
    minGBPPrice,
    maxGBPPrice,
    departments = [],
    chronology,
    excludeWithdrawn,
    country = [],
    brand = [],
    lotItemIds = [],
    groups = [],
    isWithoutReserve,
    shouldShowSoldLots,
    ...searchArgs
  }: GetAlgoliaLotsParams): Promise<AlgoliaLotsResponse> => {
    const facetFiltersRelation: Record<string, (string | number)[]> = {
      'country.name': [country].flat().filter(Boolean),
      'department.name': [departments].flat().filter(Boolean),
      brand: [brand].flat().filter(Boolean),
    }

    if (chronology) {
      const pastLotStatusQuery = shouldShowSoldLots
        ? [`${LotStatus.sold}`, `${LotStatus.withoutResults}`]
        : [`-${LotStatus.new}`]
      facetFiltersRelation['status'] =
        chronology === 'future' ? [LotStatus.new] : pastLotStatusQuery
    }

    if (groups) {
      facetFiltersRelation['groups'] = [groups].flat().filter(Boolean)
    }

    const facetFilters = generateFacets(facetFiltersRelation)

    const { filters: filtersString, ...searchArgsWithoutFilters } = searchArgs
    const filters = [filtersString]

    if (chronology) {
      const now = Math.floor(Date.now() / 1000)

      filters.push(
        chronology === 'future'
          ? `hammerTime.timestamp > ${now}`
          : `hammerTime.timestamp < ${now}`
      )
    }

    if (isWithoutReserve) {
      filters.push(`flags.isWithoutReserve:true`)
    }

    if (auctionId) {
      filters.push(`auctionId:${auctionId}`)
    }

    if (minPrice && maxPrice) {
      filters.push(
        `(price.estimateLow:${minPrice} TO ${maxPrice} OR price.estimateHigh:${minPrice} TO ${maxPrice})`
      )
    }

    if (minGBPPrice && maxGBPPrice) {
      filters.push(
        `(price.GBPLowEstimate:${minGBPPrice} TO ${maxGBPPrice} OR price.GBPHighEstimate:${minGBPPrice} TO ${maxGBPPrice})`
      )
    }

    if (excludeWithdrawn) {
      filters.push('NOT status:WD')
    }

    if (lotItemIds) {
      filters.push(lotItemIds.map((id) => `lotItemId:${id}`).join(' OR '))
    }

    const mainParams: SearchOptions = {
      attributesToRetrieve: ['*', '-footnotes', '-catalogDesc'],
      facetFilters: facetFilters.filter(Boolean),
      facets: ['*'],
      filters: filters.filter(Boolean).join(' AND '),
      ...searchArgsWithoutFilters,
    }

    const extraParams = generateFacetsQueries(facetFiltersRelation, mainParams)

    const mainResponse = algoliaRequest<AlgoliaLotsResponse>(
      index,
      '',
      mainParams
    )
    const extraResponses = extraParams.map((params) =>
      algoliaRequest<AlgoliaLotsResponse>(index, '', params)
    )
    const [mainResult, ...extraResults] = await Promise.all([
      mainResponse,
      ...extraResponses,
    ])
    executeAlgoliaQueries()

    const facets = extractFacets(extraResults)

    return { ...mainResult, facets: { ...mainResult.facets, ...facets } }
  }
}
