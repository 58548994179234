import { SearchResponse, SearchOptions } from '@algolia/client-search'

import { getDateNowUtc } from '@nx/dates'

import { AlgoliaClientInstance } from '../../algoliaClient'
import { AlgoliaValuationEvent, AlgoliaValuationEventIndices } from '../types'

export type AlgoliaValuationEventResponse =
  SearchResponse<AlgoliaValuationEvent>

type GetAlgoliaValuationEventsParams = SearchOptions & {
  index?: AlgoliaValuationEventIndices
  locationCode?: string
  inTheFuture?: boolean
}

export function getAlgoliaValuationEvents({
  algoliaRequest,
}: AlgoliaClientInstance) {
  return async ({
    index = 'valuation_events_sort_startdate_asc',
    locationCode,
    inTheFuture,
    ...searchArgs
  }: GetAlgoliaValuationEventsParams) => {
    const filters = []

    if (locationCode) {
      // TODO remove `location.id` after backend is updated
      filters.push(
        `(location.code:${locationCode} OR location.id:${locationCode})`
      )
    }

    if (inTheFuture) {
      filters.push(`dates.end.timestamp>=${getDateNowUtc().seconds}`)
    }

    const params: SearchOptions = {
      filters: filters.join(' AND '),
      facets: ['*'],
      ...searchArgs,
    }

    return algoliaRequest<AlgoliaValuationEventResponse>(index, '', params)
  }
}
