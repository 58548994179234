import { ApiError } from '@algolia/transporter'

import { AlgoliaClientInstance } from '../../algoliaClient'
import { AlgoliaValuationEventIndices, AlgoliaValuationEvent } from '../types'

function isApiError(error: unknown): error is ApiError {
  return typeof error === 'object' && (error as ApiError).status !== undefined
}

export function getAlgoliaValuationEventByObjectId({
  getAlgoliaClient,
}: AlgoliaClientInstance) {
  return async ({
    index = 'valuation_events_sort_startdate_asc',
    objectID,
  }: {
    index?: AlgoliaValuationEventIndices
    objectID: string
  }): Promise<AlgoliaValuationEvent | null> => {
    try {
      const response = await getAlgoliaClient()
        .initIndex(index)
        .getObject<AlgoliaValuationEvent>(objectID)

      return response
    } catch (error) {
      if (isApiError(error) && error.status === 404) {
        return null
      }

      throw error
    }
  }
}
