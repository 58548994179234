import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { nonNullable } from '@nx/helpers'

import { AlgoliaClientInstance } from '../algoliaClient'
import {
  GetAlgoliaLotsByObjectIDsProps,
  getAlgoliaLotsByObjectIDs,
} from './getAlgoliaLotsByObjectIDs'

export function useGetAlgoliaLotsByObjectIDs(
  props: GetAlgoliaLotsByObjectIDsProps,
  client: AlgoliaClientInstance,
  enabled = true
) {
  // We use a standard Query here because an Algolia ObjectIDs response does not include any pagination data
  const { data, error, isSuccess, isFetching, isLoading, isError } = useQuery({
    queryKey: ['lots', props],
    queryFn: () => getAlgoliaLotsByObjectIDs(client)(props),
    placeholderData: keepPreviousData,
    enabled,
  })

  const finalData = (data?.results ?? []).filter(nonNullable)

  return { data: finalData, error, isSuccess, isFetching, isLoading, isError }
}
