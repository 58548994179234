import {
  keepPreviousData as keepPreviousDataFn,
  useQuery,
} from '@tanstack/react-query'

import { AlgoliaClientInstance } from '../algoliaClient'
import { getAlgoliaAuctionsByObjectIDs } from './getAlgoliaAuctionsByObjectIDs'
import { AlgoliaAuction } from './types'

export function useGetAlgoliaAuctionsByObjectIDs(
  props: Parameters<ReturnType<typeof getAlgoliaAuctionsByObjectIDs>>[0],
  algoliaClient: AlgoliaClientInstance,
  keepPreviousData?: boolean,
  enabled?: boolean
) {
  // We use a standard Query here because an Algolia ObjectIDs response does not include any pagination data
  const { data, error, isSuccess, isFetching, isError } = useQuery({
    queryKey: ['auctions', props],
    queryFn: () => getAlgoliaAuctionsByObjectIDs(algoliaClient)(props),
    placeholderData: keepPreviousData ? keepPreviousDataFn : undefined,
    enabled,
  })

  const finalData = (data?.results as AlgoliaAuction[]) ?? []

  return { data: finalData, error, isSuccess, isFetching, isError }
}
